'use strict'

import { Swiper } from 'swiper'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import ElementPropertiesManager from './elementPropertiesManager'

import type { SwiperOptions } from 'swiper/types/swiper-options'

const defaultConfig: SwiperOptions = {
  modules: [A11y, Navigation, Pagination],
  touchEventsTarget: 'container',
  a11y: {
    enabled: true,
  },
}

interface Sliders {
  [key: string]: Swiper
}

const sliders: Sliders = {}

export default {
  initSlider(el: HTMLElement | string, config: SwiperOptions, lazy = true) {
    let element: HTMLElement | null = null

    if (typeof el === 'string') {
      element = document.querySelector<HTMLElement>(el)
    } else {
      element = el
    }

    if (!element) {
      return
    }

    let id = element.getAttribute('id') as string

    if (!id) {
      do {
        id = Math.random().toString(36).substr(2, 9)
      } while (typeof sliders[id] !== 'undefined')
    }

    element.setAttribute('data-swiper-id', id)

    const sliderConfig = Object.assign({}, defaultConfig, config)

    if ('IntersectionObserver' in window && lazy) {
      const sliderObserver = new window.IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLElement
            const slider = new Swiper(target, sliderConfig)
            sliderObserver.unobserve(target)

            ElementPropertiesManager.addProperty(target, 'slider', slider)
            sliders[id] = slider
          }
        })
      })

      sliderObserver.observe(element)
    } else {
      const slider = new Swiper(element, sliderConfig)
      ElementPropertiesManager.addProperty(element, 'slider', slider)
      sliders[id] = slider
    }

    return id
  },

  getSlider(id: string) {
    return sliders[id]
  },

  destroy(id: string) {
    const slider = sliders[id]

    if (!slider || !slider.destroy) {
      return
    }

    slider.destroy(true, true)

    delete sliders[id]

    return true
  },
}
